import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../store';
import { Button, Modal } from 'antd';
import hww1 from "./icons/hww1.jpg"
import hww2 from "./icons/hww2.jpg"
import hww3 from "./icons/hww3.jpg"
import hww4 from "./icons/hww4.jpg"
import arrow from "./icons/arrow-right-long.svg"

const WelcomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((obj) => obj.auth.user);
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate("/survey");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="welcome-container">
      <h1 style={{fontWeight: "600", textAlign: "center"}}>{t("welcome.welcome", { username: user?.name || "" })}</h1>
      <div className="hww">
            <h1 className="hww__title">{t("welcome.howWeWork")}</h1>
            <div className="hww__roadmap">
                <div className="roadmap__item">
                    <img src={hww1} alt="" className="roadmap-img" />
                    <p className="roadmap-title">{t("hww.regTitle")}</p>
                    <p className="roadmap-subtitle">{t("hww.regSubtitle")}</p>
                </div>
                <img src={arrow} alt="" className="roadmap-img-arrow" />
                <div className="roadmap__item">
                    <img src={hww2} alt="" className="roadmap-img" />
                    <p className="roadmap-title">{t("hww.strategyTitle")}</p>
                    <p className="roadmap-subtitle">{t("hww.strategySubtitle")}</p>
                </div>
                <img src={arrow} alt="" className="roadmap-img-arrow" />
                <div className="roadmap__item">
                    <img src={hww3} alt="" className="roadmap-img" />
                    <p className="roadmap-title">{t("hww.planTitle")}</p>
                    <p className="roadmap-subtitle">{t("hww.planSubtitle")}</p>
                </div>
                <img src={arrow} alt="" className="roadmap-img-arrow" />
                <div className="roadmap__item">
                    <img src={hww4} alt="" className="roadmap-img" />
                    <p className="roadmap-title">{t("hww.postTitle")}</p>
                    <p className="roadmap-subtitle">{t("hww.postSubtitle")}</p>
                </div>
            </div>
        </div>
      <div className="main-content" style={{marginTop: "40px", marginBottom: "20px"}}>

        <p style={{textAlign: "center"}}>{t("welcome.completeSurvey")}</p>
        <p style={{textAlign: "center"}}>{t("welcome.completeSurvey2")}</p>
        <button
          className="survey-button"
          onClick={() => {
            showModal();
          }}
        >
          {t("welcome.proceed")}
        </button>
        <button
          className="survey-button"
          onClick={() => dispatch(authActions.logout())}
          style={{marginTop: "20px"}}
        >
          {t('home.logout')}
        </button>
      </div>
      <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} 
        footer={[
          <Button key="submit" onClick={handleOk} type="primary">
            {t("survey.submit")}
          </Button>
        ]}>
        <p>{t("welcome.warning")}</p>
        <p>{t("welcome.warning2")}</p>
      </Modal>
    </div>
  );
};

export default WelcomePage;
