import React, { useEffect, useState } from "react";
import facebookIcon from "./icons/facebook-icon.svg"; // Replace with path to your Google icon
import xIcon from "./icons/linkedin-icon.svg"; // Replace with path to your Apple icon
import instagramIcon from "./icons/instagram-content.svg";
import { getProvidersUrls } from "../utils/auth";
import { PROVIDER } from "../constants/auth";
import { useTranslation } from "react-i18next";
import PageHeader from "../components/Header/PageHeader";
import { useNavigate } from "react-router-dom";
import { fetchWrapper } from '../helpers';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const SocialsPage = () => {
  const { t } = useTranslation();
  const [tokens, setTokens] = useState({facebook: false, instagram: false, linkedIn: false})

  const onSocialLogin = (key) => {
    window.location = getProvidersUrls()[key];
  };

  useEffect(() => {
      fetchWrapper.get(`${baseUrl}/auth/getSocialMediaTokens/`).then(res => {
        const filteredToken = {facebook: false, instagram: false, linkedIn: false}
        console.log(res)
        res.forEach((token) => {
          if(token.socialMediaType == "FACEBOOK"){
            filteredToken.facebook = true;
          }
          if(token.socialMediaType == "INSTAGRAM"){
            filteredToken.instagram = true;
          }
          if(token.socialMediaType == "LINKEDIN"){
            filteredToken.linkedIn = true;
          }
        })
        setTokens(filteredToken)
      }).catch(() => {})
  }, [])

  const navigate = useNavigate();

  return (
    <>
      <PageHeader
        title={t("socials.header")}
        onBackClick={() => {
          navigate("/home");
        }}
      />
      <h1 className="self-assessment-title">{t("socials.linkAccounts")}</h1>
      <div className="signup-wrapper">
        <main className="signup-container">
          <div className="social-login-buttons">    
                {/* <button
                  className="social-button facebook"
                  onClick={() => onSocialLogin(PROVIDER.FACEBOOK)}
                  disabled={tokens.facebook}
                >
                  <span className="icon">
                    <img src={facebookIcon} alt="" />
                  </span>
                  {tokens.facebook ? t("signin.linkedFacebook")  : t("signin.linkFacebook")}
                </button>               */}
                <button
                  className="social-button x"
                  onClick={() => onSocialLogin(PROVIDER.LINKEDIN)}
                  disabled={tokens.linkedIn}
                >
                  <span className="icon">
                    <img width={30} src={xIcon} alt="" />
                  </span>
                  {tokens.linkedIn ? t("signin.linkedLinkedIn") : t("signin.linkLinkedIn")}
                </button>
                {/* <button
                  className="social-button x"
                  onClick={() => onSocialLogin(PROVIDER.INSTAGRAM)}
                  disabled={tokens.instagram}
                >
                  <span className="icon">
                    <img width={30} src={instagramIcon} alt="" />
                  </span>
                  {tokens.instagram ? t("signin.linkedInstagram") : t("signin.linkInstagram")}
                </button> */}
          </div>
        </main>
      </div>
    </>
  );
};

export default SocialsPage;
