import penIcon from "../icons/pen-icon.svg";
import checkIcon from "../icons/check-icon-black.svg";
import { useState } from "react";
import { fetchWrapper } from "../../helpers";
import { useDispatch } from "react-redux";
import { strategiesActions } from "../../store";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const StrategyField = ({ title, content, id }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState(content);
  const dispatch = useDispatch();

  const onEdit = () => {
    if (isEdit) {
      fetchWrapper
        .put(`${baseUrl}/strategy/update/${id}`, { content: text })
        .then(() => {
          dispatch(strategiesActions.getStrategy());
        });
    }
    setIsEdit(!isEdit);
  };

  return (
    <div className="self-assessment-section">
      <div style={{ display: "flex", alignItems: "center" }}>
        <label className="self-assessment-label">{title}</label>
        <img
          className="cursor-pointer"
          width={20}
          style={{ paddingBottom: 10, marginLeft: 10 }}
          onClick={onEdit}
          src={isEdit ? checkIcon : penIcon}
        />
      </div>
      {isEdit ? (
        <textarea
          className="resize-none"
          style={{
            padding: "10px",
            width: "100%",
            height: text.length > 100 ? "200px" : "50px",
            boxShadow: "0px 0px 0px 9999px rgba(0, 0, 0, 0.3)",
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      ) : (
        <div className="self-assessment-text">{text}</div>
      )}
    </div>
  );
};
