import React from 'react';
import './PageHeader.css'; // Make sure to create a corresponding CSS file
import arrowBackIcon from './icons/arrow-back.svg'
import { useNavigate } from "react-router-dom";

const PageHeader = ({ title, onBackClick }) => {
  const navigate = useNavigate();

  return (
    <div className="header-container">
      <button className="back-button" onClick={()=>{
        onBackClick ? onBackClick(): navigate(-1)
      }}>
        <img width={40} src={arrowBackIcon} alt="" /> {/* Ideally use an SVG or icon here */}
      </button>
      <div className="header-title">{title}</div>
      {/* Add additional status bar icons if needed */}
    </div>
  );
};

export default PageHeader;
