import checkIcon from "../icons/check-icon.svg"; // Replace with path to your Google icon
import uncheckIcon from "../icons/uncheck-icon.svg"; // Replace with path to your Google icon
import questionIcon from "../icons/question-icon.svg"; // Replace with path to your Google icon
import arrowRightIcon from "../icons/arrow-right-content.svg"; // Replace with path to your Google icon
import refreshIcon from "../icons/refresh.svg"; // Replace with path to your Google icon
import moment from "moment";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchWrapper } from "../../helpers";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import penIcon from "../icons/pen-icon.svg";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const PlanCard = ({ item, index }) => {
  const { t, i18n } = useTranslation();
  const [postThemeEditText, setPostThemeEditText] = useState(false);
  const [postThemeText, setPostThemeText] = useState(item.description);
  const [post, setPost] = useState();

  const changePostThemeText = () => {
    if (postThemeEditText) {
      console.log("item", item);
      fetchWrapper.put(`${baseUrl}/content-plan/update/${item.id}`, {
        description: postThemeText,
      });
    }
    setPostThemeEditText(!postThemeEditText);
  };

  const approve = () => {
    fetchWrapper
      .post(`${baseUrl}/post/approve/`, { postId: post.id })
      .then((res) => {
        setPost({ ...post, approved: true });
      })
      .catch(() => {});
  };

  const disapprove = () => {
    fetchWrapper
      .post(`${baseUrl}/post/disapprove/`, { postId: post.id })
      .then((res) => {
        setPost({ ...post, approved: false });
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchWrapper
      .get(`${baseUrl}/post/actual/${item.id}`)
      .then((res) => {
        setPost(res);
      })
      .catch(() => {});
  }, [item, index]);

  return (
    <div key={index} className="schedule-item">
      <div className="schedule-details">
        {item.weekDay ? (
          <span className="schedule-day">{item.weekDay}</span>
        ) : (
          ""
        )}

        <span className="schedule-time">
          {moment(item.date).format("DD/MM/YYYY HH:mm")}
        </span>
        {/* <span className="schedule-type">{item.contentType}</span> */}
        {post ? (
          <>
            <span
              className={`schedule-type ${
                post.post !== "" ? "" : "--schedule-yellow-badge"
              }`}
            >
              {post.post !== ""
                ? t("plancard.generated")
                : t("plancard.notGenerated")}
            </span>
            <span
              className={`schedule-type ${
                !post.approved
                  ? "--schedule-red-badge"
                  : "--schedule-green-badge"
              }`}
            >
              {post.approved
                ? t("plancard.approved")
                : t("plancard.notApproved")}
            </span>
            <span
              className={`schedule-type ${
                !post.published
                  ? "--schedule-red-badge"
                  : "--schedule-green-badge"
              }`}
            >
              {post.published ? t("post.published") : t("post.notpublished")}
            </span>
          </>
        ) : (
          <span className={"schedule-type --schedule-yellow-badge"}>
            {t("plancard.notGenerated")}
          </span>
        )}
      </div>
      <div className="schedule-info">
        <div className="schedule-description flex items-center">
          <div style={{ maxWidth: "3rem" }}>
            <img
              style={{ width: "25px", height: "25px", maxWidth: "unset" }}
              className="cursor-pointer"
              onClick={() => changePostThemeText()}
              src={postThemeEditText ? checkIcon : penIcon}
            />
          </div>

          {postThemeEditText ? (
            <div className="pl-3 w-full">
              <textarea
                value={postThemeText}
                onChange={(e) => setPostThemeText(e.target.value)}
                className="resize-none w-full h-32 sm:h-full justify-start"
              ></textarea>
            </div>
          ) : (
            <div className="pl-3">{postThemeText}</div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="refresh-icon-placeholder">
            {post && !post.published && (
              <>
                {post.approved ? (
                  <p
                    onClick={() => disapprove()}
                    className="plan-card-button --schedule-red-badge"
                  >
                    {t("plancard.disapprove")}
                  </p>
                ) : (
                  <p
                    onClick={() => approve()}
                    className="plan-card-button --schedule-green-badge"
                  >
                    {t("plancard.approve")}
                  </p>
                )}
              </>
            )}
          </div>
          <Link to={`/create-post/${item.id}`}>
            <div className="arrow-icon-placeholder">
              <img src={arrowRightIcon} />
            </div>
          </Link>
        </div>
      </div>
      {post && (
        <Link to={`/create-post/${item.id}`}>
          <div className="justify-center px-5 py-3 mt-5 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4">
            {post.post}
          </div>
        </Link>
      )}
    </div>
  );
};
