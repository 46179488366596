import React, { useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux"
import { authActions } from '../store';
import { getRedirectUri } from '../utils/auth';

const Oauth = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(location.search);

    // access query parameters
    const provider = queryParams.get("provider");
    const code = queryParams.get("code");

    const refer = useRef(true);

    useEffect(() => {
        if (refer.current) {
            refer.current = false;
            dispatch(authActions.oauth({ provider, code, redirectUrl: getRedirectUri(provider) }));
        }
    })

    return (
        <div className="signup-wrapper">
            <header className="app-header">
            </header>
        </div>
    );
};

export default Oauth;
