import React from 'react';
import './QuestionCarousel.css';
import arrowLeft from './icons/arrow-left.svg'
import arrowRight from './icons/arrow-right.svg'

const QuestionCarousel = ({ locale, questions, currentQuestionIndex, onQuestionChange }) => {
  return (
    <div className="question-carousel">
      {currentQuestionIndex > 0 && <button className="nav-button" onClick={() => onQuestionChange(currentQuestionIndex - 1)}><img style={{maxWidth: "none"}} src={arrowLeft} /></button>}
      {questions.map((question, index) => {
        let className = 'question-item';
        if (index === currentQuestionIndex) {
          className += ' active';
        } else if (index < currentQuestionIndex - 3 || index > currentQuestionIndex + 3) {
          // className += ' hidden';
        }
        
        return (
          <div key={question?.id} className={className}>
            {index + 1}
          </div>
        );
      })}
      <button className="nav-button"  onClick={() => onQuestionChange(currentQuestionIndex + 1)}><img style={{maxWidth: "none"}} src={arrowRight} /></button>
    </div>
  );
};

export default QuestionCarousel;
