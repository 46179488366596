import React, { useState } from 'react';
import PageHeader from '../../components/Header/PageHeader';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store';
import {useLocation} from 'react-router-dom';
import { LanguageHeader } from '../../components/LanguageHeader';

export const CreateNewPassword = ({ onPasswordCreated }) => {
  const {t} = useTranslation()
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === confirmPassword) {
      console.log(location.state.username)
      dispatch(authActions.newPassword({ username: location.state.username, newPassword: password}))
      navigate('/home')
    } else {
      alert(t('newPassword.noMatch'));
    }
  };

  return (
    <>
        <LanguageHeader/>
        <PageHeader title={t('newPassword.header')} />
        <div className="forgot-password-container">
        <div className="content">
            <p>{t('newPassword.passwordRequirements')}</p>
            <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t('newPassword.placeholderCreate')}
            />
            <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={t('newPassword.placeholderConfirm')}
            />
            <button onClick={handleSubmit}>{t('newPassword.continue')}</button>
        </div>
        </div>
    </>
  );
};
