import React from 'react';
import './BottomNav.css'; // Make sure to link the CSS file
import menuIcon from '../icons/menu.svg'; // Replace with path to your Google icon
import shareIcon from '../icons/share.svg'; // Replace with path to your Google icon
import takeNoteIcon from '../icons/take-note.svg'; // Replace with path to your Google icon
import arrowLeftIcon from '../icons/arrow-left.svg'; // Replace with path to your Google icon
import arrowRightIcon from '../icons/arrow-right.svg'; // Replace with path to your Google icon
import fontIcon from '../icons/font.svg'; // Replace with path to your Google icon

const BottomNav = ({onClickNext, onClickBefore, onClickMenu}) => {
  return (
    <nav className="bottom-nav">
      <div className="nav-item" onClick={onClickMenu}>
        <img src={menuIcon} alt="Menu" />
      </div>
      {/* <div className="nav-item">
        <img src={shareIcon} alt="Share" />
      </div> */}
      {/* <div className="nav-item">
        <img src={takeNoteIcon} alt="Documents" />
      </div> */}
      <div className="nav-item" onClick={onClickBefore}>
        <img src={arrowLeftIcon} alt="Undo" />
      </div>
      {/* <div className="nav-item">
        <img src={fontIcon} alt="Text Size" />
      </div> */}
      <div className="nav-item" onClick={onClickNext}>
        <img src={arrowRightIcon} alt="History" />
      </div>
    </nav>
  );
};

export default BottomNav;
