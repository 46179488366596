import React, { useEffect, useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { strategiesActions } from "../../store";
import "./PersonalBrandStrategy.css";
import BottomNav from "./BottomNav";
import SideNav from "./SideNav";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StrategyField } from "./StrategyField";
import coffee from "../icons/coffee.png";

export const PersonalBrandStrategy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const strategies = useSelector((obj) => obj.strategy.strategies);
  const generating = useSelector((obj) => obj.strategy.generating);
  const user = useSelector((obj) => obj.auth.user);
  const blocks = useSelector((obj) => obj.strategy.blocks);
  const [activeBlockIndex, setActiveBlockIndex] = useState();
  const [activeStrategy, setActiveStrategy] = useState();
  const [navVisible, setNavVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(strategiesActions.getStrategy());
  }, []);

  useEffect(() => {
    setActiveBlockIndex(0);
  }, [blocks]);

  useEffect(() => {
    if (Array.isArray(strategies)) {
      const filteredStrategy = strategies.filter(
        (s) => s.section === blocks[activeBlockIndex]
      );
      setActiveStrategy(filteredStrategy);
    }
  }, [activeBlockIndex, strategies]);

  const handleBlockChange = (newIndex) => {
    if (newIndex >= 0 && newIndex < blocks.length) {
      setActiveBlockIndex(newIndex);
      setNavVisible(false);
    }
  };

  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  return (
    <>
      <div className="header-strategy-wrapper">
        <div className="header-strategy">
          <PageHeader title={t("personalBrand.header")} />
          {generating ? (
            <>
              <h1 className="self-assessment-title">
                {t("functions.generatingBrand")}
              </h1>
            </>
          ) : (
            <>
              {strategies.length && (
                <>
                  <h1 className="self-assessment-title">
                    {blocks[activeBlockIndex]}
                  </h1>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {!generating ? (
        <>
          {!strategies.length ? (
            <>
              <div class="flex flex-col items-center justify-center">
                <div className="welcome-container">
                  <div className="main-content">
                    <h1>
                      {t("welcome.welcome", { username: user?.name || "" })}
                    </h1>
                    <p>{t("welcome.completeSurvey")}</p>
                    <button
                      className="survey-button"
                      onClick={() => {
                        navigate("/survey");
                      }}
                    >
                      {t("welcome.proceed")}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="self-assessment-content">
                {activeStrategy?.map((active) => (
                  <StrategyField
                    key={active.id}
                    title={active.title}
                    content={active.content}
                    id={active.id}
                  />
                ))}

                <BottomNav
                  onClickMenu={() => setNavVisible(!navVisible)}
                  onClickBefore={() => handleBlockChange(activeBlockIndex - 1)}
                  onClickNext={() => {
                    handleBlockChange(activeBlockIndex + 1);
                  }}
                />
                <SideNav
                  onClick={handleBlockChange}
                  items={blocks}
                  isVisible={navVisible}
                  toggleNav={toggleNav}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div class="flex flex-col items-center justify-center">
            <div className="welcome-container">
              <div className="main-content">
                <p style={{"whiteSpace": "pre-line"}}>{t("personalBrand.coffee")}</p>
                <p><img src={coffee} alt="" className="coffee-img" /></p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
