import React, { useState, useEffect } from "react";
import "./SurveyPage.css";
import activeMic from "../icons/mic-on.svg";
import inactiveMic from "../icons/mic-off.svg";
import QuestionCarousel from "../../components/Carousel/QuestionCarousel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { questionsActions, strategiesActions } from "../../store";
import { message, notification, Space, Button } from "antd";
import { useTranslation } from "react-i18next";
import AudioRecorder from "audio-recorder-polyfill";
import i18n from "i18next";
import { jwtDecode } from "jwt-decode";
import { fetchWrapper } from "../../helpers";
import { authActions } from "../../store";

const SurveyPage = () => {
  window.MediaRecorder = AudioRecorder;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState([]);
  const [isMicActive, setIsMicActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const questions = useSelector((obj) => obj.question.questions);
  const user = useSelector((obj) => obj.auth.user);
  const [locale, setLocale] = useState(
    jwtDecode(user?.accessToken)?.languageGeneration
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();

  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type="primary"
          size="small"
          onClick={() => navigate("/subscription-plans")}
        >
          {t("posts.goToRate")}
        </Button>
      </Space>
    );
    notificationApi.open({
      message: t("functions.error"),
      description: t("posts.limit"),
      btn,
      key,
    });
  };

  const handleQuestionChange = (newIndex) => {
    if (newIndex >= 0 && newIndex < questions.length) {
      setCurrentQuestionIndex(newIndex);
    }
  };

  const refreshToken = () => {
    fetchWrapper.get(`${baseUrl}/auth/refresh-token`).then((res) => {
      dispatch(authActions.updateUser());
      setLocale(jwtDecode(res?.accessToken)?.languageGeneration);
    });
  };

  const clearAnswer = () => {
    const nAnswer = [...answer];
    nAnswer[currentQuestionIndex] = "";
    setAnswer(nAnswer);
  };
  const submitAnswer = async () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const requestBody = questions.map((q, index) => ({
        UserId: user.id,
        QuestionId: q.id,
        answer: answer[index],
      }));

      message.loading(t("functions.savingAnswersForStrategy"));

      try {
        await dispatch(questionsActions.answerQuestions(requestBody));
        await dispatch(strategiesActions.generateStrategy()).then((res) => {
          if (res.error) {
            openNotification();
          } else {
            setLoading(true);
            message.success(t("functions.successfullySendToGenerate"));
            setLoading(false);
            navigate("/home");
          }
        });
      } catch (err) {
        message.error(t("functions.wrong"));
      }
    }
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setIsMicActive(true);
        const recorder = new MediaRecorder(stream);
        recorder.start();
        setMediaRecorder(recorder);
        recorder.addEventListener("dataavailable", (e) => {
          message.loading(t("functions.loading"));
          const audioBlob = new Blob([e.data], { type: "audio/wav" });
          const audioFile = new File([audioBlob], "recording.wav", {
            type: "audio/wav",
          });
          const formData = new FormData();
          formData.append("audio", audioFile);

          axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/upload-audio`,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          })
            .then((data) =>
              onAnswerChangeMicrophone(
                `${answer[currentQuestionIndex] || ""}${data.data}`
              )
            )
            .catch((error) => console.error(error));
        });
      })
      .catch((error) => {
        message.error(t("functions.microphone"));
      });
  };

  const stopRecording = () => {
    setIsMicActive(false);
    mediaRecorder?.stop();
  };

  useEffect(() => {
    return () => {
      mediaRecorder?.state === "recording" && mediaRecorder.stop();
    };
  }, [mediaRecorder]);

  useEffect(() => {
    dispatch(questionsActions.getQuestions());
    refreshToken();
  }, []);

  const toggleMic = () => {
    if (isMicActive) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const onAnswerChangeMicrophone = (value) => {
    const nAnswer = [...answer];
    nAnswer[currentQuestionIndex] = value;
    setAnswer(nAnswer);
  };

  const onAnswerChange = (value) => {
    const nAnswer = [...answer];
    nAnswer[currentQuestionIndex] = value;
    setAnswer(nAnswer);
    // if (
    //   i18n.language === "en" &&
    //   (new RegExp(/^[a-zA-Z0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
    //     value
    //   ) ||
    //     value == "")
    // ) {
    //   const nAnswer = [...answer];
    //   nAnswer[currentQuestionIndex] = value;
    //   setAnswer(nAnswer);
    // } else if (
    //   i18n.language === "ru" &&
    //   (new RegExp(/^[а-яА-Я0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
    //     value
    //   ) ||
    //     value == "")
    // ) {
    //   const nAnswer = [...answer];
    //   nAnswer[currentQuestionIndex] = value;
    //   setAnswer(nAnswer);
    // } else {
    //   messageApi.open({
    //     type: "error",
    //     content: t("functions.wrongLayout"),
    //   });
    // }
  };

  return (
    <>
      {contextHolder}
      {notificationContextHolder}
      <div className="survey-page">
        <div className="navbar">
          <div className="menu-icon"></div>
          <div className="title">{t("survey.survey")}</div>
        </div>
        <QuestionCarousel
          questions={questions}
          currentQuestionIndex={currentQuestionIndex}
          onQuestionChange={handleQuestionChange}
        />
        <div className="question">
          {questions[currentQuestionIndex]?.[`question_${locale}`]}
        </div>
        <div className="mic-icon cursor-pointer" onClick={toggleMic}>
          <img src={isMicActive ? activeMic : inactiveMic} alt="" />
        </div>
        {isMicActive ? (
          <div className="text-center mt-4">{t("survey.recording")}</div>
        ) : (
          <div className="text-center mt-4">{t("survey.tapToStart")}</div>
        )}
        <div className="text-area-container">
          <textarea
            maxLength={1000}
            placeholder={questions[currentQuestionIndex]?.[`tip_${locale}`]}
            value={answer[currentQuestionIndex] || ""}
            onChange={(e) => onAnswerChange(e.target.value)}
          />
        </div>
        {loading ? (
          <div className="title">{t("survey.wait")}</div>
        ) : (
          <div className="button-container">
            <button className="clear-button" onClick={clearAnswer}>
              {t("survey.clear")}
            </button>
            <button
              className="submit-button"
              disabled={!answer[currentQuestionIndex]?.length}
              onClick={submitAnswer}
            >
              {t("survey.submit")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SurveyPage;
