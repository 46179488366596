import React, { useState, useEffect } from "react";
import "../Survey/SurveyPage.css";
import activeMic from "../icons/mic-on.svg";
import copyIcon from "../icons/copy-icon.svg";
import arrowDownIcon from "../icons/arrow-down.svg";
import arrowUpIcon from "../icons/arrow-up.svg";
import inactiveMic from "../icons/mic-off.svg";
import QuestionCarousel from "../../components/Carousel/QuestionCarousel";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { contentPlanActions, postActions, questionsActions } from "../../store";
import { message } from "antd";
import PageHeader from "../../components/Header/PageHeader";
import { useTranslation } from "react-i18next";
import AudioRecorder from "audio-recorder-polyfill";
import i18n from "i18next";

export const CreatePost = ({ openNotification }) => {
  window.MediaRecorder = AudioRecorder;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contentPlan = useSelector((obj) => obj.contentPlan.contentPlan);
  const leadingQuestionsLoading = useSelector(
    (obj) => obj.contentPlan.leadingQuestionsLoading
  );
  const postLoading = useSelector((obj) => obj.post.loading);
  const [isMicActive, setIsMicActive] = useState(false);
  const [openedLeading, setOpenedLeading] = useState(true);
  const [answer, setAnswer] = useState("");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setIsMicActive(true);
        const recorder = new MediaRecorder(stream);
        recorder.start();
        setMediaRecorder(recorder);
        recorder.addEventListener("dataavailable", (e) => {
          message.loading(t("functions.loading"));
          const audioBlob = new Blob([e.data], { type: "audio/wav" });
          const audioFile = new File([audioBlob], "recording.wav", {
            type: "audio/wav",
          });
          const formData = new FormData();
          formData.append("audio", audioFile);

          axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/upload-audio`,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          })
            .then(
              (data) =>
                data.data &&
                setAnswer((answer) => `${answer || ""}${data.data}`)
            )
            .catch((error) => console.error(error));
        });
      })
      .catch((error) => {
        message.error(t("functions.microphone"));
      });
  };

  const stopRecording = () => {
    mediaRecorder?.stop();
    // You might want to do something with the audio chunks here, like creating a blob and saving or playing it
    const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
    // Resetting audio chunks after recording is stopped
    setAudioChunks([]);
    setIsMicActive(false);
    // Here, you could save the blob or do something else with it
  };

  useEffect(() => {
    return () => {
      mediaRecorder?.state === "recording" && mediaRecorder.stop();
    };
  }, [mediaRecorder]);

  const toggleMic = () => {
    if (isMicActive) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const submitAnswer = async () => {
    message.loading(t("functions.generatingPost"));
    // dispatch()
    if (answer) {
      await dispatch(
        contentPlanActions.createContentPlanDetails({
          id: contentPlan.id,
          details: answer,
        })
      );
    }

    await dispatch(postActions.generatePost(contentPlan.id)).then((res) => {
      if (res.error) {
        openNotification();
      }
    });

    // try {
    //   await dispatch(postActions.generatePost(contentPlan.id));
    // } catch {
    //   // openNotification();
    //   console.log('aaaa');
    // }
  };

  const generateLeadingQuestions = () => {
    message.loading(t("functions.loadingLeading"));
    dispatch(contentPlanActions.generateLeadingQuestions(contentPlan.id));
  };

  const clipboardCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success(t("functions.copied"));
  };

  const onChangeTextarea = (e) => {
    setAnswer(e.target.value);
    // if (
    //   i18n.language === "en" &&
    //   (new RegExp(/^[a-zA-Z0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
    //     e.target.value
    //   ) ||
    //     e.target.value == "")
    // ) {
    //   setAnswer(e.target.value);
    // } else if (
    //   i18n.language === "ru" &&
    //   (new RegExp(/^[а-яА-Я0-9()*_\-!#$%^&*,.()@';:><?"^№~}{\'\+=\s\b]+$/).test(
    //     e.target.value
    //   ) ||
    //     e.target.value == "")
    // ) {
    //   setAnswer(e.target.value);
    // } else {
    //   messageApi.open({
    //     type: "error",
    //     content: t("functions.wrongLayout"),
    //   });
    // }
  };

  return (
    <>
      {contextHolder}
      <div className="survey-page">
        {/* <PageHeader title={"Create Post"} />
      <div>
        <p className="self-assessment-title" style={{ fontSize: 13 }}>{contentPlan.description}</p>
      </div> */}
        <div
          className={`text-justify flex ${
            contentPlan?.leadingQuestions ? "justify-between" : "justify-center"
          } px-6`}
          style={{ textAlign: "center" }}
        >
          {contentPlan?.leadingQuestions ? (
            <img
              className="cursor-pointer"
              onClick={() => setOpenedLeading(!openedLeading)}
              src={openedLeading ? arrowUpIcon : arrowDownIcon}
            />
          ) : null}
          <div>{t("post.leadingQuestions")}</div>
          {contentPlan?.leadingQuestions ? (
            <img
              className="cursor-pointer"
              onClick={() => {
                clipboardCopy(contentPlan?.leadingQuestions);
              }}
              src={copyIcon}
            />
          ) : null}
        </div>
        <div className="justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl border border-black border-solid bg-purple-200 bg-opacity-50 px-4">
          {contentPlan?.leadingQuestions ? (
            <div className="whitespace-pre-line">
              {openedLeading
                ? contentPlan?.leadingQuestions
                : `${contentPlan?.leadingQuestions.substring(0, 190)}...`}
            </div>
          ) : (
            <div className="text-center">
              {t("post.noLeading1")} <br /> {t("post.noLeading2")}
            </div>
          )}
        </div>
        {contentPlan?.leadingQuestions ? null : (
          <button
            disabled={leadingQuestionsLoading}
            onClick={generateLeadingQuestions}
            className="submit-button p-2 m-auto"
            style={{
              fontSize: 15,
              padding: 5,
              borderRadius: 20,
              marginBottom: 5,
            }}
          >
            {leadingQuestionsLoading
              ? t("functions.loading")
              : t("functions.generate")}
          </button>
        )}
        <div className="mic-icon" onClick={toggleMic}>
          <img src={isMicActive ? activeMic : inactiveMic} alt="" />
        </div>
        {isMicActive && (
          <div className="text-center mt-4">{t("survey.recording")}</div>
        )}
        <div className="text-area-container">
          <textarea
            placeholder={t("survey.type")}
            value={answer}
            onChange={(e) => onChangeTextarea(e)}
          />
        </div>
        <div className="button-container">
          <button
            className="clear-button"
            onClick={() => setAnswer()}
            disabled={postLoading}
          >
            {t("survey.clear")}
          </button>
          <button
            className="submit-button"
            disabled={!answer?.length || postLoading}
            onClick={submitAnswer}
          >
            {postLoading ? t("functions.leadingQuestions") : t("survey.submit")}
          </button>
        </div>
      </div>
    </>
  );
};
