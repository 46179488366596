import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "../helpers";

const PrivateRoute = () => {
  const authUser = useSelector((obj) => obj.auth.user);

  if (!authUser) {
    return <Navigate to="/sign-in" state={{ from: history.location }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
