import "./App.css";
import { CreateContentPlan } from "./pages/ContentPlan/CreateContentPlan";
import { CreateNewPassword } from "./pages/ForgotPassword/CreateNewPassword";
import { InputCode } from "./pages/ForgotPassword/InputCode";
import { SendPassword } from "./pages/ForgotPassword/SendPassword";
import HomePage from "./pages/Home/HomePage";
import SocialsPage from "./pages/Socials";
import { PersonalBrandStrategy } from "./pages/PersonalBrandStrategy/PersonalBrandStrategy";
import SignInPage from "./pages/SignIn";
import SignupPage from "./pages/SignUp";
import SurveyPage from "./pages/Survey/SurveyPage";
import WelcomePage from "./pages/WelcomePage";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { history } from "./helpers";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { strategiesActions } from "./store";
import { Post } from "./pages/CreatePost/Posts";
import Oauth from "./pages/Oauth";
import io from "socket.io-client";
import { Select } from "antd";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import localeEn from "./locale/locale.en";
import localeRu from "./locale/locale.ru";
import PrivateRoute from "./components/PrivateRouter";
import { ProfilePage } from "./pages/Profile";
import { SubscriptionPlansPage } from "./pages/SubscriptionPlans";
import { Posts } from "./pages/Posts/Posts";
import { GeneratePost } from "./pages/GeneratePost/GeneratePost";
import { HowWeWorkPage } from "./pages/HowWeWork/HowWeWorkPage";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: localeEn,
    },
    ru: {
      translation: localeRu,
    },
  },
  lng: "ru",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const dispatch = useDispatch();
  const authUser = useSelector((obj) => obj.auth.user);
  // const socketRef = useRef(); // Socket:DEPRECATED
  // const [sendSocket, setSendSocket] = useState(); // Socket:DEPRECATED

  const generatingStrategy = useSelector((obj) => obj.strategy.generating);
  const interval = useRef(null);

  history.navigate = useNavigate();
  history.location = useLocation();

  // Socket:DEPRECATED
  // useEffect(() => {
  //   const socket = io.connect(process.env.BASE_DOMAIN);
  //   socketRef.current = socket;

  //   setSendSocket(socket);
  //   return () => {
  //     socket.disconnect();
  //     socketRef.current = false;
  //     setSendSocket(null);
  //   };
  // }, []);

  useEffect(() => {
    if (authUser) {
      dispatch(strategiesActions.getStrategy());
    }
  }, [authUser]);

  useEffect(() => {
    if (generatingStrategy) {
      interval.current = setInterval(() => {
        dispatch(strategiesActions.getStrategy());
      }, 5000);
    } else {
      clearInterval(interval.current);
      interval.current = null;
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [dispatch, generatingStrategy, interval]);

  // Socket:DEPRECATED
  // useEffect(() => {
  //   if (socketRef.current && authUser) {
  //     socketRef.current.on("connect", function () {
  //       socketRef.current.emit("subscribe", authUser.id);
  //     });

  //     socketRef.current.on("finishedStrategy", () => {
  //       dispatch(strategiesActions.getStrategy());
  //     });
  //   }
  // }, [socketRef.current, authUser]);

  useEffect(() => {
    i18n.changeLanguage(
      localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).languageGeneration
        : "en"
    );
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to={authUser ? "home" : "/sign-in"} />}
      />
      <Route path="/sign-up" element={<SignupPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/forgot-password" element={<SendPassword />} />
      <Route path="/input-code" element={<InputCode />} />
      <Route path="/new-password" element={<CreateNewPassword />} />
      <Route path="/oauth" element={<Oauth />} />

      <Route element={<PrivateRoute />}>
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/survey" element={<SurveyPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/socials" element={<SocialsPage />} />
        <Route path="/personal-brand" element={<PersonalBrandStrategy />} />
        <Route path="/content-plan" element={<CreateContentPlan />} />
        <Route path="/create-post/:contentId" element={<Post />} />
        <Route path="/posts/" element={<Posts />} />
        <Route path="/generate-post/" element={<GeneratePost />} />
        <Route path="/subscription-plans" element={<SubscriptionPlansPage />} />
        <Route path="/how-we-work" element={<HowWeWorkPage />} />
      </Route>
    </Routes>
  );
}

export default App;
