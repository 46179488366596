import { store, authActions } from '../store';

const request = (method) => {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        if (body) {
            if(!(body instanceof FormData)) {
                requestOptions.headers['Content-Type'] = 'application/json';
            }
            requestOptions.body = (body instanceof FormData) ? body : JSON.stringify(body);
        } 
        return fetch(url, requestOptions).then(handleResponse);
    }
}

const authHeader = (url) => {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = authToken();
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
    if (isLoggedIn && isApiUrl) {
        return { 'accessToken': `${token}` };
    } else {
        return {};
    }
}

const authToken = () => {
    return store.getState().auth.user?.accessToken;
}

const handleResponse = (response) => {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && authToken()) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                const logout = () => store.dispatch(authActions.logout());
                logout();
            }

            const error = (data && data.message) || response.error;
            if (Array.isArray(error)) {
                return Promise.reject(error.join('; '));
            } else {
                return Promise.reject(error);
            }
        }

        return data;
    });
}

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    patch: request('PATCH'),
    delete: request('DELETE')
};