import { useEffect, useState } from "react";
import PageHeader from "../../components/Header/PageHeader";
import { useTranslation } from "react-i18next";
import { fetchWrapper } from "../../helpers";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import arrowRightIcon from "../icons/arrow-right-content.svg"; // Replace with path to your Google icon
import { PlanCard } from "../ContentPlan/PlanCard";
import "./style.css";
import { Button, notification, Space } from 'antd';

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const Posts = () => {
  const [posts, setPosts] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const [strategy, setStrategy] = useState(false)
  const [token, setToken] = useState("")

  const openNotification = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Space>
          <Button type="primary" size="small" onClick={() => navigate("/personal-brand")}>
            {t("functions.generate")}
          </Button>
        </Space>
      );
      notificationApi.open({
        message: t("functions.error"),
        description: t("contentPlan.strategyError"),
        btn,
        key,
      });
    };

    const openNotificationLinkedIn = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Space>
          <Button type="primary" size="small" onClick={() => navigate("/profile")}>
            {t("profile.linkAccount")}
          </Button>
        </Space>
      );
      notificationApi.open({
        message: t("contentPlan.tokenError"),
        btn,
        key,
      });
    };

    const submit = () => {
      if(token === ""){
          openNotificationLinkedIn()
      }else if(strategy === false){
          openNotification()
      }else{
        navigate("/generate-post")
      }
  }

  useEffect(() => {
    fetchWrapper.get(`${baseUrl}/post/all`).then((res) => {
      setPosts(res);
    });

    fetchWrapper.get(`${baseUrl}/strategy/actual/`).then(res => {
      if(res[0] != null){
          setStrategy(true)
      }
    })
    fetchWrapper.get(`${baseUrl}/auth/getSocialMediaTokens/`).then(res => {
      res.forEach((t) => {
          if(t.socialMediaType == "LINKEDIN"){
            setToken(t.accessToken)
          }
      })
  })
  }, []);

  return (
    <>
      {notificationContextHolder}
      <PageHeader
        title={t("posts.header")}
        onBackClick={() => {
          navigate("/home");
        }}
      />
      <button
        className="post-button"
        onClick={() => submit()}
      >
        {t("home.createPost")}
      </button>
      <div className="posts">
        {/* {posts.map((post) => (
                    <div className="post-card">
                        <p className="post-card__text">{post.post}</p>
                        <p className="post-card__hashtags">{post.hashtags}</p>
                    </div>
                ))} */}

        {posts.map((post, index) => (
          <PlanCard
            item={{ ...post.ContentPlan, id: post.ContentPlan.id }}
            index={index}
          />
        ))}
      </div>
    </>
  );
};
