import hww1 from "../icons/hww1.jpg"
import hww2 from "../icons/hww2.jpg"
import hww3 from "../icons/hww3.jpg"
import hww4 from "../icons/hww4.jpg"
import arrow from "../icons/arrow-right-long.svg"
import "./style.css"
import { useTranslation } from 'react-i18next';

export const HowWeWorkPage = () => {
    const {t} = useTranslation()
    return(
        <div className="hww">
            <h1 className="hww__title">Как мы работаем</h1>
            <div className="hww__roadmap">
                <div className="roadmap__item">
                    <img src={hww1} alt="" className="roadmap-img" />
                    <p className="roadmap-title">{t("hww.regTitle")}</p>
                    <p className="roadmap-subtitle">{t("hww.regSubtitle")}</p>
                </div>
                <img src={arrow} alt="" className="roadmap-img-arrow" />
                <div className="roadmap__item">
                    <img src={hww2} alt="" className="roadmap-img" />
                    <p className="roadmap-title">{t("hww.strategyTitle")}</p>
                    <p className="roadmap-subtitle">{t("hww.strategySubtitle")}</p>
                </div>
                <img src={arrow} alt="" className="roadmap-img-arrow" />
                <div className="roadmap__item">
                    <img src={hww3} alt="" className="roadmap-img" />
                    <p className="roadmap-title">{t("hww.planTitle")}</p>
                    <p className="roadmap-subtitle">{t("hww.planSubtitle")}</p>
                </div>
                <img src={arrow} alt="" className="roadmap-img-arrow" />
                <div className="roadmap__item">
                    <img src={hww4} alt="" className="roadmap-img" />
                    <p className="roadmap-title">{t("hww.postTitle")}</p>
                    <p className="roadmap-subtitle">{t("hww.postSubtitle")}</p>
                </div>
            </div>
        </div>
    )
}