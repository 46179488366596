import React, { useState } from 'react';
import './ForgotPassword.css';
import PageHeader from '../../components/Header/PageHeader';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { authActions } from '../../store';
import { useDispatch } from 'react-redux';
import { LanguageHeader } from '../../components/LanguageHeader';

export const SendPassword = ({ onSendCode }) => {
    const {t} = useTranslation()
    const [input, setInput] = useState('');
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(authActions.recoveryRequest({username: input}))
        navigate('/input-code', {state: {username: input}})
        // onSendCode(); // This function should handle the transition to the next step
    };

    return (
        <>
            <LanguageHeader/>
            <PageHeader title={t('sendPassword.header')}/>
            <div className="forgot-password-container">
                <div className="content">
                    <p>={t('sendPassword.title')}</p>
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder={t('sendPassword.placeholder')}
                    />
                    <button onClick={handleSubmit}>{t('sendPassword.sendCode')}</button>
                </div>
            </div>
        </>

    );
};
