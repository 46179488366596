import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../helpers';
import { message } from 'antd';

const name = 'question';

const createInitialState = () => {
    return {
        questions: [],
        loading: false
    }
}

const createExtraActions = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;

    const getQuestions = () => {
        return createAsyncThunk(
            `${name}/questions`,
            async () => await fetchWrapper.get(`${baseUrl}/questions`)
        );
    }

    const answerQuestions = () => {
        return createAsyncThunk(
            `${name}/survey`,
            async (body) => {
                await fetchWrapper.post(`${baseUrl}/survey`, body)
            }
        ); 
    }

    return {
        getQuestions: getQuestions(),
        answerQuestions: answerQuestions()
    };
}

const createExtraReducers = (builder) => {

    const getQuestions = () => {
        const { pending, fulfilled, rejected } = extraActions.getQuestions;
            builder.addCase(pending, (state) => {
                state.loading = true
            })
            builder.addCase(fulfilled, (state, action) => {
                state.questions = action.payload;
                state.loading = false
            })
            builder.addCase(rejected, (state, action) => {
                state.keys = { error: action.error };
                state.loading = false
            })
    }

    const answerQuestions = () => {
        const { pending, fulfilled, rejected } = extraActions.answerQuestions;
        builder.addCase(pending, (state) => {
            state.loading = true
        })
        builder.addCase(fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(rejected, (state, action) => {
            state.keys = { error: action.error };
            state.loading = false
        })
    }

    getQuestions()
    answerQuestions()
}

const extraActions = createExtraActions();
const slice = createSlice({
    name: name,
    initialState: createInitialState(),
    reducers: {},
    extraReducers: builder => {
        const extraReducers = createExtraReducers(builder);
    }
}
);

export const questionsActions = { ...slice.actions, ...extraActions };
export const questionsReducer = slice.reducer;