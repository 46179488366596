import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../helpers';
import { message } from 'antd';

const name = 'strategy';

const createInitialState = () => {
    return {
        strategies: [],
        blocks: [],
        loading: false,
        generating: false
    }
}

const createExtraActions = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;

    const getStrategy = () => {
        return createAsyncThunk(
            `${name}/strategy`,
            async () => await fetchWrapper.get(`${baseUrl}/strategy/actual`)
        );
    }

    const generateStrategy = () => {
        return createAsyncThunk(
            `${name}/strategy/generate`,
            async () => {
                await fetchWrapper.post(`${baseUrl}/strategy/generate`)
                return await fetchWrapper.get(`${baseUrl}/strategy/actual`)
            }
        );
    }

    return {
        getStrategy: getStrategy(),
        generateStrategy: generateStrategy()
    };
}

const createExtraReducers = (builder) => {

    const getStrategy = () => {
        const { pending, fulfilled, rejected } = extraActions.getStrategy;
        builder.addCase(pending, (state) => {
            state.loading = true
        })
        builder.addCase(fulfilled, (state, action) => {
            const blocks = new Set();
            if (action.payload?.status) {
                state.loading = false
                state.generating = true;
            } else {
                state.strategies = action.payload;
                action.payload.forEach(strategy => {
                    blocks.add(strategy.section)
                })
                state.blocks = [...blocks]
                state.loading = false
                state.generating = false
            }
        })
        builder.addCase(rejected, (state, action) => {
            state.strategies = { error: action.error };
            state.loading = false
        })
    }

    const generateStrategy = () => {
        const { pending, fulfilled, rejected } = extraActions.generateStrategy;
        builder.addCase(pending, (state) => {
            state.loading = true
        })
        builder.addCase(fulfilled, (state, action) => {
            state.generating = true;
            state.loading = false
        })
        builder.addCase(rejected, (state, action) => {
            state.loading = false
        })
    }

    getStrategy()
    generateStrategy()
}

const extraActions = createExtraActions();
const slice = createSlice({
    name: name,
    initialState: createInitialState(),
    reducers: {},
    extraReducers: builder => {
        const extraReducers = createExtraReducers(builder);
    }
}
);

export const strategiesActions = { ...slice.actions, ...extraActions };
export const strategiesReducer = slice.reducer;