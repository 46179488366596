import React from "react";
import "./BottomNavContentPlan.css"; // Make sure to link the CSS file
import menuIcon from "../icons/menu.svg"; // Replace with path to your Google icon
import shareIcon from "../icons/share.svg"; // Replace with path to your Google icon
import takeNoteIcon from "../icons/take-note.svg"; // Replace with path to your Google icon
import arrowLeftIcon from "../icons/arrow-left.svg"; // Replace with path to your Google icon
import arrowRightIcon from "../icons/arrow-right.svg"; // Replace with path to your Google icon
import fontIcon from "../icons/font.svg"; // Replace with path to your Google icon
import plusIcon from "../icons/plus-icon.svg"; // Replace with path to your Google icon

const BottomNav = ({
  onClickNext,
  onClickBefore,
  onClickMenu,
  onClickFont,
  isFont = true,
}) => {
  return (
    <nav className="bottom-nav-content">
      <div className="nav-item-content" onClick={onClickBefore}>
        <img src={arrowLeftIcon} alt="Undo" />
      </div>
      {!isFont && (
        <div
          className="nav-item-content"
          onClick={() => {
            onClickFont();
          }}
        >
          <img src={isFont ? fontIcon : plusIcon} alt="Text Size" />
        </div>
      )}
      <div
        className="nav-item-content"
        onClick={() => (isFont ? onClickNext() : null)}
      >
        <img src={arrowRightIcon} alt="History" />
      </div>
    </nav>
  );
};

export default BottomNav;
