import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { questionsReducer } from './questions.slice';
import { strategiesReducer } from './strategy.slice';
import { contentPlanReducer } from './contentPlan.slice';
import { postReducer } from './post.slice';


const combinedReducer = combineReducers({
    auth: authReducer,
    question: questionsReducer,
    strategy: strategiesReducer,
    contentPlan: contentPlanReducer,
    post: postReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'auth/logout') {
        state = undefined;
    }
    return combinedReducer(state, action);
}

export const store = configureStore({
    reducer: rootReducer,
});

export * from './auth.slice';
export * from './questions.slice';
export * from './strategy.slice';
export * from './contentPlan.slice';
export * from './post.slice';