export default {
    "signin": {
        "signIn": "Войти",
        "welcomeBack": "Добро пожаловать !",
        "introText": "I-DEA Social: твой умный помощник в социальных сетях.",
        "emailLabel": "Адрес электронной почты",
        "inputPassword": "Введите пароль",
        "passwordLabel": "Пароль",
        "forgotPassword": "Забыли пароль ?",
        "dontHaveAccount": "У вас нет аккаунта?",
        "or": "или",
        "continue": "Продолжить",
        "signInGoogle": "Войти через Google",
        "signInApple": "Войти через Apple",
        "signInFacebook": "Войти с помощью Facebook",
        "signInLinkedIn": "Войдите с помощью LinkedIn",
        "linkFacebook": "Привязать аккаунт Facebook",
        "linkLinkedIn": "Привязать аккаунт LinkedIn",
        "linkInstagram": "Привязать аккаунт Instagram",
        "linkedFacebook": "Facebook привязан",
        "linkedLinkedIn": "LinkedIn привязан",
        "linkedInstagram": "Instagram привязан",
        "privacy": "Условия <offerLink>публичной оферты</offerLink> и <privacyLink>политики</privacyLink> мною прочитаны полностью, все условия мне понятны, со всеми условиями я согласен",
        "payment": "Порядок оплаты",
        "contacts": 'Телефон: +7 701 743 0008 \r\n Email: kzideasocial@gmail.com'
    },
    "signup": {
        "signUp": "Зарегистрироваться",
        "fullName": "Полное имя",
        "passwordRequirements": "Убедитесь, что ваш пароль содержит не менее 8 символов: 1 заглавную, 1 строчную, 1 цифру и 1 специальный символ.",
        "haveAccount": "У вас уже есть аккаунт?",
        "signUpGoogle": "Зарегистрируйтесь через Google",
        "signUpApple": "Зарегистрируйтесь через Apple",
        "signUpFacebook": "Зарегистрируйтесь через Facebook",
        "signUpLinkedIn": "Зарегистрируйтесь через LinkedIn",
        "lang": "Язык"
    },
    "welcome": {
      "welcome": "Добро пожаловать,{{username}}, в Idea Social!",
      "howWeWork": "Как мы работаем",
      "completeSurvey": "Чтобы успешно создать личный \n бренд в LinkedIn, важно разработать персональную стратегию.",
      "completeSurvey2": "Исходя из этой стратегии, будет формироваться контент-план и публикации, учитывающие ваши цели и предпочтения.",
      "proceed": "Перейти к опросу",
      "warning": "Очень важно максимально полно ответить на вопросы, чтобы стратегия была приближена к вашим ожиданиям.",
      "warning2": "В процессе нашего с Вами взаимодействия мы ее скорректируем, с нашей точки зрения,для более точной персонализации."
    },
    "profile":{
      "header": "Профиль",
      "buySubscription": "Оформить подписку",
      "upgradeSubscription": "Обновить план",
      "extendSubscription": "Продлить подписку",
      "name": "Имя",
      "login": "Логин",
      "subscription": "Подписка до",
      "no": "Нет",
      "yes": "Да",
      "lang": "Язык",
      "linkAccount": "Привязать аккаунт"
    },
    "subscriptionPlans":{
      "header": "Тарифы",
      "Strategy": "Стратегии",
      "ContentPlan": "Контент планы",
      "LeadingQuestions": "Наводящие вопросы",
      "Post": "Посты",
      "Theme": "Темы",
      "PostImages": "Изображения постов",
      "free": "Бесплатный",
      "paid": "Платный",
      "infoButton": "Описание функционала",
      "modalTitle": "Функционал сервиса I-DEA SOCIAL",
      "modalText": `
      1.⁠ ⁠Цифровая стратегия клиента:\r\n
      На основе небольшого опросника создается портрет клиента, который мы называем цифровой стратегией. В дальнейшем посты будут создаваться на основе предложенных клиентом тем, а также с учетом его уникального портрета. Цифровая стратегия обновляется только после одобрения пользователем обновленной версии. Мы анализируем запросы и посты клиента, чтобы понять, какие темы ему интересны и как он хочет о них рассказывать. Это позволяет нам учиться создавать посты, которые более точно отражают его цели и интересы. Как только клиент одобрит изменения, наша платформа начинает создавать контент, который отражает новые предпочтения и помогает лучше взаимодействовать с целевой аудиторией. Это обеспечивает более глубокую персонализацию и улучшает результаты взаимодействия с социальными сетями.\r\n
      2.⁠ ⁠Создание постов с помощью ИИ:\r\n
      Зная портрет клиента, наш ИИ создает посты для LinkedIn, которые отражают предпочтения, опыт и стремления клиента. Наш ИИ анализирует данные и генерирует контент, который не только резонирует с целевой аудиторией клиента, но и способствует достижению его профессиональных целей. Мы стремимся, чтобы каждый пост был максимально персонализирован, содержал актуальную информацию и способствовал усилению профессионального имиджа клиента.\r\n
      3.⁠ ⁠Контент-план на неделю:\r\n
      Наша платформа позволяет создавать контент-план на неделю, что дает возможность заранее планировать посты и публиковать их по расписанию. Это обеспечивает постоянное присутствие в LinkedIn без необходимости ежедневного вмешательства. Клиенты могут легко просматривать и редактировать запланированные посты, добавлять свои комментарии и реагировать на обратную связь от аудитории. Это упрощает процесс управления контентом и позволяет поддерживать активное взаимодействие с сетью профессионалов.\r\n
      4.⁠ ⁠Публикация вне контент-плана:\r\n
      Платформа также поддерживает возможность публикации постов без привязки к контент-плану. Если у клиента возникает интересная идея для поста на определенную тему, ИИ может создать и опубликовать этот пост сразу, без необходимости следовать заранее утвержденному контент-плану. Это дает клиентам гибкость быстро реагировать на текущие события, делиться актуальными мыслями и сохранять живой и динамичный характер своего профиля на LinkedIn.
      `,
      "submit": "Понятно",
      "unlimitedRate":"Безлимит на весь функционал",
      "active": "Активный"
    },
    "survey": {
        "survey": "Опрос",
        "recording": "Запись голоса.. Нажмите чтобы остановить запись",
        "type": "или напишите свой ответ здесь...",
        "wait": "Пожалуйста, подождите, мы сохраняем ваши ответы и генерируем информацию. ",
        "clear": "Очистить",
        "submit": "Далее",
        "tapToStart": "Нажмите, чтобы продиктовать Ваш ответ",
      },
      "home": {
        "welcomeBack": "Добро пожаловать, ",
        "logout": "Выйти",
        "contentPlan": "Создать контент-план",
        "strategy": "Цифровая стратегия",
        "createPost": "Создать публикацию",
        "linkSocials": "Привязать соц. сети",
        "profile": "Профиль",
        "personalBrandStrategy": "Публичный образ человека, ведущего профессиональную деятельность, который возникает в сознании целевой аудитории",
        "contentPlanTitle": "График публикаций",
        "createPostTitle": "Быстрый способ оставаться на связи с вашим сообществом. Любой пост, которым вы делитесь, транслируется в каналы ваших контактов на их домашней странице LinkedIn",
        "subscriptionDaysLeft": "Количество дней до окончания подписки: {{days}}"
      },
      "personalBrand": {
        "coffee": `Это может занять 5-7 минут.\r\nКак насчёт чашечки бодрящего кофе для вдохновения пока формируется стратегия?`,
        "header": "Цифровая стратегия",
        "newStrategy": "Создать новую стратегию",
        "Name": "Имя",
        "Occupation/Title": "Заголовок",
        "Short Bio": "Короткое описание",
        "Accolades & Awards": "Награды",
        "Strengths": "Сильные стороны",
        "Weaknesses": "Область для улучшения",
        "Passions": "Увлечения",
        "Values": "Ценности",
        "What Sets You Apart": "Что отличает вас",
        "Unique Benefits You Can Provide": "Уникальные преимущества, которые вы можете предоставить",
        "Determine Who You are Trying to Reach and Communicate With": "Целевая аудитория",
        "Understand Their Needs, Wants, and Challenges": "Понять их потребности, желания и проблемы»: «Понять их потребности, желания и проблемы",
        "How Should Daniel Address These Needs and Challenges": "Как {{username}} следует решать эти нужды и решать проблемы",
        "Colors": "Цвета",
        "Imagery": "Образы",
        "Personal Logo or Signature": "Личный логотип или подпись",
        "Areas of Expertise": "Области знаний",
        "Content Formats": "Форматы контента",
        "Content Themes": "возможные темы контента",
        "Engagement Strategy": "Стратегия взаимодействия",
        "Short-term Goals (1-2 years)": "Краткосрочные цели (1-2 года)",
        "Long-term Goals (3-5 years)": "Долгосрочные цели (3-5 лет)",
        "Feedback Channel": "Канал обратной связи",
        "Adaptation Strategy": "Стратегия адаптации",
        "Conclusion": "Заключение",
      },
      "sendPassword": {
        "header": "Забыли пароль",
        "title": "Пожалуйста, введите свой адрес электронной почты, чтобы получить код.",
        "placeholder": "Адрес электронной почты",
        "sendCode": "Отправить код"
      },
      "inputCode": {
        "header": "Входной код",
        "title": "Пожалуйста, введите код, отправленный на адрес электронной почты.",
        "placeholder": "Введите код",
        "proceed": "Продолжить"
      },
      "newPassword": {
        "noMatch": "Пароли не совпадают.",
        "header": "Создать новый пароль",
        "passwordRequirements": "Убедитесь, что ваш пароль содержит не менее 8 символов: 1 заглавную, 1 строчную, 1 цифру и 1 специальный символ.",
        "placeholderCreate": "Создать новый пароль",
        "placeholderConfirm": "Подтвердите пароль",
        "continue": "Продолжать"
      },
      "post": {
        "leadingQuestions": "Наводящие вопросы",
        "noLeading1": "Для данного контент-плана нет наводящих вопросов",
        "noLeading2": "Нажмите «Создать», чтобы получить содержательные вопросы для идеального поста",
        "loading": "Загрузка...",
        "generate": "Генерировать",
        "headerPost": "Пост",
        "headerCreate": "Создать пост",
        "suggestion": "Предложения по посту",
        "published": "Опубликовано",
        "notpublished": "Не опубликован",
        "planned": "Планируется",
        "hashtagSuggestion": "Предложения по хештегам",
        "mediaSuggestion": "Предложения СМИ",
        "image": "Изображение",
        "noImage": "Нет изображения",
        "uploadImage": "Загрузить изображение",
        "save": "Сохранить",
        "processingImage": "Пожалуйста, подождите, мы обрабатываем изображение",
        "generateImage": "Создать изображение",
        "generatingImage": "Генерируем изображение",
        "generating": "Мы генерируем ваш пост",
        "pdf": "Скачать PDF"
      },
      "contentPlan": {
        "header": "Контент-план",
        "week": "Неделя",
        "add": "Нажмите + для выбора социальных сетей",
        "frequency": "Частота",
        "elementsTitle": "Выберите или добавьте элементы для контента этой недели",
        "custom": "Напишите здесь свои запросы на индивидуальный контент",
        "create": "Создать контент план",
        "tokenError": "LinkedIn не привязан",
        "strategyError": "У вас не сгенерирована личная стратегия",
      },
      "functions": {
        "generate": "Создать",
        "generating": "Создание...",
        "successfullyGenerated": "Успешно создано!",
        "successfullyFinished": "Успешно завершено!",
        "successfullyUploaded": "Успешно загружено!",
        "successfullySendToGenerate": "Успешно начат процесс формирования!",
        "wrong": "Что-то пошло не так",
        "loading": "Загрузка...",
        "microphone": "Ошибка доступа к микрофону. Проверьте свое устройство или дайте разрешение.",
        "generatingPost": "Пожалуйста, подождите, создается пост",
        "generatingContentPlan": "Пожалуйста, подождите, создается контент план",
        "loadingLeading": "Загрузка наводящих вопросов",
        "copied": "Скопировано!",
        "savingAnswersForStrategy": "Сохранение ответов и создание стратегии",
        "close": "Закрыть",
        "loggedIn": "Успешно авторизован!",
        "registered": "Успешная регистрация!",
        "incorrectCreds": "Неправильные учетные данные",
        "limitReached": "Достигнут лимит действий, использовано {{count}} из {{max}} генераций по вашему тарифу",
        "recoveryRequested": "Востановление пароля запрошенно",
        "newPassword": "Пароль изменен",
        "generatingBrand": "Пожалуйста, подождите, создается стратегия",
        "error": "Ошибка",
        "wrongLayout": "Неправильная раскладка, измените язык ввода"
      },
      "socials": {
        "header": "Социальные сети",
        "linkAccounts": "Привяжите аккаунты социальных сетей для возможности авто-постинга"
      },
      "plancard": {
        "generated": "Пост сгенерирован",
        "notGenerated": "Пост не сгенерирован",
        "approved": "Одобрен",
        "notApproved": "Не одобрен",
        "approve": "Одобрить на публикацию",
        "disapprove": "Отменить публикацию",
      },
      "posts":{
        "header": "Посты",
        "generatePost": "Сгенерировать пост",
        "postDescription": "Введите тему для генерации поста или продиктуйте ее используя микрофон",
        "postDetails": "Подробности",
        "postDate": "Дата публикации",
        "publish": "Опубликовать",
        "regenerate": "Перегенерировать пост",
        "limit": "Лимит текущего тарифа исчерпан, вы можете оформить платную подписку, чтобы использовать сервис дальше",
        "goToRate": "Перейти к тарифам"
      },
      "hww":{
        "regTitle": "Регистрация в сервисе",
        "regSubtitle": "Зарегистрируйтесь в сервисе",
        "strategyTitle": "Создание стратегии",
        "strategySubtitle": "Пройдите опросник и ИИ предоставит персональную стратегию",
        "planTitle": "Создание контент плана",
        "planSubtitle": "На основе стратегии ИИ предложит вам контент-план",
        "postTitle": "Публикация постов",
        "postSubtitle": "На основе стратегии и контент-плана ИИ предложит вам варианты постов"
      }
}